@use "variables";

@font-face {
  font-family: '#{variables.$icomoon-font-family}';
  src:  url('#{variables.$icomoon-font-path}/#{variables.$icomoon-font-family}.eot?wxdssx');
  src:  url('#{variables.$icomoon-font-path}/#{variables.$icomoon-font-family}.eot?wxdssx#iefix') format('embedded-opentype'),
    url('#{variables.$icomoon-font-path}/#{variables.$icomoon-font-family}.ttf?wxdssx') format('truetype'),
    url('#{variables.$icomoon-font-path}/#{variables.$icomoon-font-family}.woff?wxdssx') format('woff'),
    url('#{variables.$icomoon-font-path}/#{variables.$icomoon-font-family}.svg?wxdssx##{variables.$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{variables.$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-telehealth-doctor {
  &:before {
    content: variables.$icon-telehealth-doctor; 
  }
}
.icon-schedule {
  &:before {
    content: variables.$icon-schedule; 
  }
}
.icon-event-available {
  &:before {
    content: variables.$icon-event-available; 
  }
}
.icon-mail-outline {
  &:before {
    content: variables.$icon-mail-outline; 
  }
}
.icon-videocam {
  &:before {
    content: variables.$icon-videocam; 
  }
}
.icon-powered-by {
  &:before {
    content: variables.$icon-powered-by; 
  }
}
.icon-powered-by-logo {
  &:before {
    content: variables.$icon-powered-by-logo; 
  }
}
.icon-pm-logo-large {
  &:before {
    content: variables.$icon-pm-logo-large; 
  }
}
.icon-link {
  &:before {
    content: variables.$icon-link; 
  }
}
.icon-error-circle {
  &:before {
    content: variables.$icon-error-circle; 
  }
}
.icon-error-circle-outline {
  &:before {
    content: variables.$icon-error-circle-outline; 
  }
}
.icon-equalizer {
  &:before {
    content: variables.$icon-equalizer; 
  }
}
.icon-mic {
  &:before {
    content: variables.$icon-mic; 
  }
}
.icon-mic-outline {
  &:before {
    content: variables.$icon-mic-outline; 
  }
}
.icon-play-arrow {
  &:before {
    content: variables.$icon-play-arrow; 
  }
}
.icon-book {
  &:before {
    content: variables.$icon-book; 
  }
}
.icon-add {
  &:before {
    content: variables.$icon-add; 
  }
}
.icon-add-circle {
  &:before {
    content: variables.$icon-add-circle; 
  }
}
.icon-add-circle-outline {
  &:before {
    content: variables.$icon-add-circle-outline; 
  }
}
.icon-cancel {
  &:before {
    content: variables.$icon-cancel; 
  }
}
.icon-inbox {
  &:before {
    content: variables.$icon-inbox; 
  }
}
.icon-remove {
  &:before {
    content: variables.$icon-remove; 
  }
}
.icon-remove-circle {
  &:before {
    content: variables.$icon-remove-circle; 
  }
}
.icon-remove-circle-outline {
  &:before {
    content: variables.$icon-remove-circle-outline; 
  }
}
.icon-insert-chart {
  &:before {
    content: variables.$icon-insert-chart; 
  }
}
.icon-edit {
  &:before {
    content: variables.$icon-edit; 
  }
}
.icon-arrow-down {
  &:before {
    content: variables.$icon-arrow-down; 
  }
}
.icon-arrow-up {
  &:before {
    content: variables.$icon-arrow-up; 
  }
}
.icon-circle {
  &:before {
    content: variables.$icon-circle; 
  }
}
.icon-hamburger-menu {
  &:before {
    content: variables.$icon-hamburger-menu; 
  }
}
.icon-arrow-left {
  &:before {
    content: variables.$icon-arrow-left; 
  }
}
.icon-arrow-right {
  &:before {
    content: variables.$icon-arrow-right; 
  }
}
.icon-circle-outline {
  &:before {
    content: variables.$icon-circle-outline; 
  }
}
.icon-phone {
  &:before {
    content: variables.$icon-phone; 
  }
}
.icon-pin-drop {
  &:before {
    content: variables.$icon-pin-drop; 
  }
}
.icon-pin {
  &:before {
    content: variables.$icon-pin; 
  }
}
.icon-apps {
  &:before {
    content: variables.$icon-apps; 
  }
}
.icon-arrow-left-2 {
  &:before {
    content: variables.$icon-arrow-left-2; 
  }
}
.icon-arrow-down-3 {
  &:before {
    content: variables.$icon-arrow-down-3; 
  }
}
.icon-arrow-up-3 {
  &:before {
    content: variables.$icon-arrow-up-3; 
  }
}
.icon-arrow-right-2 {
  &:before {
    content: variables.$icon-arrow-right-2; 
  }
}
.icon-cancel-circle {
  &:before {
    content: variables.$icon-cancel-circle; 
  }
}
.icon-check {
  &:before {
    content: variables.$icon-check; 
  }
}
.icon-error {
  &:before {
    content: variables.$icon-error; 
  }
}
.icon-group {
  &:before {
    content: variables.$icon-group; 
  }
}
.icon-mood-happy {
  &:before {
    content: variables.$icon-mood-happy; 
  }
}
.icon-mood-bad {
  &:before {
    content: variables.$icon-mood-bad; 
  }
}
.icon-group-outline {
  &:before {
    content: variables.$icon-group-outline; 
  }
}
.icon-person {
  &:before {
    content: variables.$icon-person; 
  }
}
.icon-person-outline {
  &:before {
    content: variables.$icon-person-outline; 
  }
}
.icon-radio-button-active {
  &:before {
    content: variables.$icon-radio-button-active; 
  }
}
.icon-account-box {
  &:before {
    content: variables.$icon-account-box; 
  }
}
.icon-account-circle {
  &:before {
    content: variables.$icon-account-circle; 
  }
}
.icon-account-badge {
  &:before {
    content: variables.$icon-account-badge; 
  }
}
.icon-refresh {
  &:before {
    content: variables.$icon-refresh; 
  }
}
.icon-bookmarks {
  &:before {
    content: variables.$icon-bookmarks; 
  }
}
.icon-bookmark-outline {
  &:before {
    content: variables.$icon-bookmark-outline; 
  }
}
.icon-check-circle {
  &:before {
    content: variables.$icon-check-circle; 
  }
}
.icon-delete {
  &:before {
    content: variables.$icon-delete; 
  }
}
.icon-face {
  &:before {
    content: variables.$icon-face; 
  }
}
.icon-helper {
  &:before {
    content: variables.$icon-helper; 
  }
}
.icon-cancel-circle-outline {
  &:before {
    content: variables.$icon-cancel-circle-outline; 
  }
}
.icon-home {
  &:before {
    content: variables.$icon-home; 
  }
}
.icon-info-circle {
  &:before {
    content: variables.$icon-info-circle; 
  }
}
.icon-info-circle-outline {
  &:before {
    content: variables.$icon-info-circle-outline; 
  }
}
.icon-print {
  &:before {
    content: variables.$icon-print; 
  }
}
.icon-warning {
  &:before {
    content: variables.$icon-warning; 
  }
}
.icon-search {
  &:before {
    content: variables.$icon-search; 
  }
}
.icon-cog {
  &:before {
    content: variables.$icon-cog; 
  }
}
.icon-check-badge {
  &:before {
    content: variables.$icon-check-badge; 
  }
}
.icon-work {
  &:before {
    content: variables.$icon-work; 
  }
}
.icon-user-md {
  &:before {
    content: variables.$icon-user-md; 
  }
}
.icon-lightbulb-outline {
  &:before {
    content: variables.$icon-lightbulb-outline; 
  }
}
.icon-calendar {
  &:before {
    content: variables.$icon-calendar; 
  }
}
.icon-business-center {
  &:before {
    content: variables.$icon-business-center; 
  }
}
.icon-star {
  &:before {
    content: variables.$icon-star; 
  }
}
.icon-star-empty {
  &:before {
    content: variables.$icon-star-empty; 
  }
}

