@use "components/variables"

// margin all
.ma-xs
  margin: variables.$sp-xs
.ma-s
  margin: variables.$sp-s
.ma-m
  margin: variables.$sp-m
.ma-l
  margin: variables.$sp-l
.ma-xl
  margin: variables.$sp-xl
.ma-xxl
  margin: variables.$sp-xxl

// margin top
.mt-xs
  margin-top: variables.$sp-xs
.mt-s
  margin-top: variables.$sp-s
.mt-m
  margin-top: variables.$sp-m
.mt-l
  margin-top: variables.$sp-l
.mt-xl
  margin-top: variables.$sp-xl
.mt-xxl
  margin-top: variables.$sp-xxl

// margin right
.mr-xs
  margin-right: variables.$sp-xs
.mr-s
  margin-right: variables.$sp-s
.mr-m
  margin-right: variables.$sp-m
.mr-l
  margin-right: variables.$sp-l
.mr-xl
  margin-right: variables.$sp-xl
.mr-xxl
  margin-right: variables.$sp-xxl

// margin bottom
.mb-xs
  margin-bottom: variables.$sp-xs
.mb-s
  margin-bottom: variables.$sp-s
.mb-m
  margin-bottom: variables.$sp-m
.mb-l
  margin-bottom: variables.$sp-l
.mb-xl
  margin-bottom: variables.$sp-xl
.mb-xxl
  margin-bottom: variables.$sp-xxl

// margin left
.ml-xs
  margin-left: variables.$sp-xs
.ml-s
  margin-left: variables.$sp-s
.ml-m
  margin-left: variables.$sp-m
.ml-l
  margin-left: variables.$sp-l
.ml-xl
  margin-left: variables.$sp-xl
.ml-xl
  margin-left: variables.$sp-xxl