@use "compass-mixins/compass/css3"
@use "components/variables"
@use "mixins/button-style"


.map-marker
  display: inline-block
  padding: 4px
  width: 16px
  height: 16px
  font-size: 13px
  font-weight: 600
  text-align: center
  background: red
  color: variables.$white
  @include css3.border-radius(50%)

.icons-pin
  font-size: 28px
  display: inline-block
  position: relative
  top: 20px
  left: -7px

  &:after
    display: block
    width: 5px
    height: 5px
    color: variables.$white
    font-size: 12px
    position: relative
    top: -23px
    left: -1px
    margin: 0 auto
    font-weight: 800
    font-family: helvetica
    @include css3.text-shadow(rgba(61,61,61, 0.7) 1px 1px 1px,rgba(61,61,61, 0.7) -.5px -.5px 1px)

.indent
  margin-left: 27px