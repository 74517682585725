.hide
  display: none
  visibility: hidden

.d-block
  display: block

.d-inline-block
  display: inline-block

.d-table
  display: table

.d-inline
  display: inline

.btnGroup, .d-table
  &:after
    content: ""
    display: table
    clear: both
  &:before
    display: table
    content: ""
