@use "variables"

ul
  list-style-position: inside
  color: variables.$gray-1


  &.bullets
    list-style-type: disc

  &.no-bullets
    list-style-type: none
  
  &.list-s
    li
      padding: 2px 0
      font-size: variables.$fs-s

  &.list-m
    li
      padding: 4px 0
      font-size: variables.$fs-s

  &.list-l
    li
      padding: 6px 0
      font-size: variables.$fs-m

  &.two-col
    -webkit-column-count: 2
    -webkit-column-gap: 10px
    -moz-column-count: 2
    -moz-column-gap: 10px
    -o-column-count: 2
    -o-column-gap: 10px
    column-count: 2
    column-gap: 10px

  &.three-col
    -webkit-column-count: 3
    -webkit-column-gap: 10px
    -moz-column-count: 3
    -moz-column-gap: 10px
    -o-column-count: 3
    -o-column-gap: 10px
    column-count: 3
    column-gap: 10px
  
  &.four-col
    -webkit-column-count: 4
    -webkit-column-gap: 10px
    -moz-column-count: 4
    -moz-column-gap: 10px
    -o-column-count: 4
    -o-column-gap: 10px
    column-count: 4
    column-gap: 10px

  &.six-col
    -webkit-column-count: 6
    -webkit-column-gap: 10px
    -moz-column-count: 6
    -moz-column-gap: 10px
    -o-column-count: 6
    -o-column-gap: 10px
    column-count: 6
    column-gap: 10px