@use "variables"
@use "compass-mixins/compass/css3"

.tab-nav
  display: table
  margin-bottom: -1px

  li
    margin: 0
    display: inline-block
    border:
      top: 1px solid variables.$gray-4
      right: 1px solid variables.$gray-4
      bottom: 1px solid variables.$gray-4
      left: 0
    border-collapse: collapse
    float: left
    position: relative
    background: variables.$gray-5

    a
      padding: 1em
      color: variables.$gray-1

    &:hover
      background: #fff

      a
        color: variables.$ui-link

    &:first-child
      border-left: 1px solid #ccc
      @include css3.border-radius(variables.$br-m 0 0 0)

    &:last-child
      @include css3.border-radius(0 variables.$br-m 0 0)

    &.active
      background: #fff
      border-bottom: 1px solid #fff

      a
        color: variables.$gray-1

        &:hover
          text-decoration: none
          cursor: default

      &:after
        position: absolute
        top: -6px
        top: 0\9 //IE 8 and below fix
        left: 0
        width: 100%
        height: 4px
        background: variables.$ui-link
        content: ''
        -webkit-transition: -webkit-transform 0.3s
        transition: transform 0.3s
        -webkit-transform: translate3d(0,150%,0)
        transform: translate3d(0,150%,0)

    &.disabled
      cursor: not-allowed
      background: #f9f9f9
      color: #bbb

      a
        color: #bbb
        cursor: not-allowed

        &:hover
          text-decoration: none
