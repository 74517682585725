@use "sass:string";
@use "shared";

// The default box-sizing model when no argument is provided to the box-sizing mixin: [ content-box | border-box | padding-box ]
//
// The browser default is content-box, compass defaults to border-box.
$default-box-sizing: border-box !default;

// Change the box model for Mozilla, Webkit, IE8 and the future
//
// @param $bs
//   [ content-box | border-box ]

@mixin box-sizing($bs: $default-box-sizing) {
  $bs: string.unquote($bs);
  @include shared.experimental(box-sizing, $bs,
    -moz, -webkit, not(-o), not(-ms), not(-khtml), official
  );
}
