@use 'style-guide/components/variables';

@media print {
  /*
  * Chrome, Internet Explorer, and Edge all use the Bootstrap 'xs' screen size for printing.
  * Firefox and Safari use the 'sm' and larger screens, i.e. 'md' and 'lg'.
  * Some Bootstrap grid classes have been redefined in this file to achieve a consistent
  * print experience across all browsers.
  */

  .button,
  .show-more,
  #facets,
  #kyruus-search-widget {
    display: none;
  }

  a,
  a:visited {
    color: variables.$gray-1;
  }

  .horizontal-rule:before,
  .horizontal-rule-wide:before {
    border-top: none;
    padding-top: 0;
  }

  .summary-field-title {
    font-weight: 700;
    margin-bottom: 0;
  }

  .about-panel-row {
    display: inline-block;
    line-height: 1.25;

    .about-panel-item {
      width: 100%;
      padding-bottom: 0;

      &:after {
        content: ',';
        padding-right: 5px;
        font-weight: 400;
      }

      &.is-hierarchy {
        font-weight: 500;

        ul {
          list-style: none;
          display: inline-block;
          font-weight: 400;
          margin-left: 0;
          margin-top: 0;

          :first-child:before {
            content: ':';
            font-weight: 500;
            padding-right: 5px;
          }

          :last-child:after {
            content: '';
            padding-right: 0;
          }
        }
      }

      &.has-logo-image {
        margin-bottom: 0;
        line-height: inherit;
      }
    }

    &:last-child .about-panel-item:after {
      content: '';
      padding-right: 0;
    }
  }

  .experience-field-title {
    font-weight: 700;
    font-size: 14px;
    padding-top: 1px;
    padding-bottom: 0;
    width: 25%;
    display: inline-block;
    vertical-align: top;

    & + div {
      width: 75%;
      display: inline-block;
      margin-bottom: 0;
      vertical-align: top;
    }
  }

  .experience-section {
    margin-bottom: 0;
    line-height: 1.25;
  }

  .experience-title {
    display: inline;
    margin-bottom: 0;

    a {
      display: inline;
    }
  }

  .experience-subtitle {
    color: inherit;
    display: inline;

    &:before {
      content: '|';
      padding-left: 5px;
      padding-right: 5px;
    }

    br {
      content: '';

      &:before {
        content: '|';
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  #left-panels {
    min-height: 180px;
  }

  #provider-details-summary {
    padding-bottom: 0;

    .col-xs-12 {
      width: 100%;
      float: left;
      text-align: left;
      margin-left: 155px;

      &:first-child {
        width: auto;
        margin-left: 0;
        position: absolute;
      }
    }

    .col-xs-offset-2,
    .col-sm-offset-2 {
      margin-left: 0;
    }
  }

  #provider-details-locations {
    margin-left: 140px;

    .location {
      margin-top: 0;
      width: 50%;
      display: inline-block;
      vertical-align: top;

      ul {
        margin-top: 0;

        li:first-child {
          padding-top: 0;
        }
      }
    }

    .mb-s {
      margin-bottom: 3px;
    }

    .mt-s {
      margin-top: 3px;
    }
  }

  .ratings {
    padding-bottom: 10px;

    .ratings-column {
      width: 100px;
    }

    .star-column {
      .stars {
        height: auto;
        display: block;
      }

      .review-count {
        line-height: 1.25;
        margin-left: 120px;
      }

      .metric {
        line-height: 1.25;
        margin-left: 100px;
      }
    }
  }

  .hide.hidden-row {
    display: table-row !important;
    visibility: visible;
  }

  .panel {
    border: none;

    &.mb-m {
      margin-bottom: 0;
    }
  }

  #right-panels .panel {
    border-top: 1px solid variables.$gray-1;
    border-radius: 0;
  }

  .profile-summary {
    page-break-inside: avoid;

    .col-xs-4 {
      width: 25%;
    }

    .col-xs-8 {
      width: 75%;
    }

    .col-xs-12 {
      width: 75%;
    }
  }

  .provider {
    // To fix the right border in Safari
    margin-right: -1px;

    .col-sm-3 {
      width: 25%;
      float: left;
    }

    .col-sm-9 {
      width: 75%;
      float: left;
    }

    .col-sm-4,
    .col-sm-8 {
      width: 100%;
    }
  }

  .results {
    // To fix the right border in Safari
    width: 99%;

    .col-md-4 {
      width: 100%;
    }
  }

  #profile-summary {
    > div:first-child {
      align-self: center;
      margin-top: 16px;
    }
  }

  #profile-locations { 
    order: -1;
    #profile-locations-section {
      .map-container {
        display: none;
      }
      max-height: auto;
      &::after {
        background: none;
      }
      ~ button {
        display: none;
      }
    }
  }

  #SearchLinksContainer {
    display: none;
  }

  .profile-navigation-container {
    display: none;
  }

  div[id^='profile-'] {
    page-break-inside: avoid; /* Prevents breaking inside sections */
  }

  #profile-care-team div[data-testid^='MiniProviderCardList-Provider'],
  #profile-similar-providers div[data-testid^='MiniProviderCardList-Provider'] {
    width: calc(50% - 8px);
  }

  a[data-testid^='mini-provider-card-'],
  button[data-testid^='mini-provider-card-'] {
    display: none;
  }

  #provider-location-for-print {
    display: block;
  }

  #profile-network-affiliations > div {
    border-top: none;
  }
}