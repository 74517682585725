
//This is for offsetting a grid column to the left
.offset-left-s
  margin-left: -10px
  margin-right: 10px
.offset-left-m
  margin-left: -20px
  margin-right: 20px
.offset-left-l
  margin-left: -30px
  margin-right: 30px
.offset-left-xl
  margin-left: -40px
  margin-right: 40px
.offset-left-xl
  margin-left: -50px
  margin-right: 50px

//This is for offsetting a grid column to the right
.offset-right-s
  margin-left: 10px
  margin-right: -10px
.offset-right-m
  margin-left: 20px
  margin-right: -20px
.offset-right-l
  margin-left: 40px
  margin-right: -10px