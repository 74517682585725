@use "mixins/bootstrap-sass-mixins"
@use "components/bootstrap-sass/variables"
@use "components/bootstrap-sass/close"
@use "components/bootstrap-sass/modals"
@use "components/variables" as variables2

/*!
 * Bootstrap Modal
 *
 * Copyright Jordan Schroter
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Boostrap 3 patch for for bootstrap-modal. Include BEFORE bootstrap-modal.css!
 */

body.modal-open, .modal-open .navbar-fixed-top,  .modal-open .navbar-fixed-bottom
  margin-right: 0

.modal
  left: 50%
  bottom: auto
  right: auto
  z-index: 1050
  padding: 0
  width: 500px
  margin-left: -250px
  background-color: #ffffff
  border: 1px solid #999999
  border: 1px solid rgba(0, 0, 0, 0.2)
  border-radius: 6px
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5)
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5)
  background-clip: padding-box

.modal.container
  max-width: none

.modal-backdrop
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 1040


/*!
 * Bootstrap Modal
 *
 * Copyright Jordan Schroter
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 */

.modal-open
  overflow: hidden

/* add a scroll bar to stop page from jerking around */
.modal-open.page-overflow .page-container,
.modal-open.page-overflow .page-container .navbar-fixed-top,
.modal-open.page-overflow .page-container .navbar-fixed-bottom,
.modal-open.page-overflow .modal-scrollable
  overflow-y: scroll

@media (max-width: 979px)
  .modal-open.page-overflow .page-container .navbar-fixed-top,
  .modal-open.page-overflow .page-container .navbar-fixed-bottom
    overflow-y: visible


.modal-scrollable
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  overflow: auto

.modal
  outline: none
  position: absolute
  margin-top: 0
  top: 50%
  overflow: visible /* allow content to popup out (i.e tooltips) */

.modal.fade
  top: -100%
  -webkit-transition: opacity 0.3s linear, top 0.3s ease-out, bottom 0.3s ease-out, margin-top 0.3s ease-out  
  -moz-transition: opacity 0.3s linear, top 0.3s ease-out, bottom 0.3s ease-out, margin-top 0.3s ease-out
  -o-transition: opacity 0.3s linear, top 0.3s ease-out, bottom 0.3s ease-out, margin-top 0.3s ease-out
  transition: opacity 0.3s linear, top 0.3s ease-out, bottom 0.3s ease-out, margin-top 0.3s ease-out

.modal.fade.in
  top: 50%

.modal-body
  max-height: none
  overflow: visible

.modal.modal-absolute
  position: absolute
  z-index: 950

.modal .loading-mask
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  background: #fff
  border-radius: 6px

.modal-backdrop.modal-absolute
  position: absolute
  z-index: 940

.modal-backdrop, .modal-backdrop.fade.in
  opacity: 0.7
  filter: alpha(opacity=70)
  background: #fff

.modal.container
  width: 940px
  margin-left: -470px

/* Modal Overflow */

.modal-overflow.modal
  top: 1%

.modal-overflow.modal.fade
  top: -100%

.modal-overflow.modal.fade.in
  top: 1%

.modal-overflow .modal-body
  overflow: auto
  -webkit-overflow-scrolling: touch

/* Responsive */

@media (min-width: 1200px)
  .modal.container
    width: 1170px
    margin-left: -585px

@media (max-width: 979px)
  .modal, .modal.container, .modal.modal-overflow
    top: 1%
    right: 1%
    left: 1%
    bottom: auto
    width: auto !important
    height: auto !important
    margin: 0 !important
    padding: 0 !important
  
  .modal.fade.in, .modal.container.fade.in, .modal.modal-overflow.fade.in
    top: 1%
    bottom: auto
  
  .modal-body, .modal-overflow .modal-body
    position: static
    margin: 0
    height: auto !important
    max-height: none !important
    overflow: visible !important
  
  .modal-footer, .modal-overflow .modal-footer 
    position: static

.loading-spinner
  position: absolute
  top: 50%
  left: 50%
  margin: -12px 0 0 -12px

.animated
  -webkit-animation-duration: 1s
  -moz-animation-duration: 1s
  -o-animation-duration: 1s
  animation-duration: 1s
  -webkit-animation-fill-mode: both
  -moz-animation-fill-mode: both
  -o-animation-fill-mode: both
  animation-fill-mode: both

@-webkit-keyframes shake
  0%, 100%
    -webkit-transform: translateX(0)
  10%, 30%, 50%, 70%, 90%
    -webkit-transform: translateX(-10px)
  20%, 40%, 60%, 80%
    -webkit-transform: translateX(10px)

@-moz-keyframes shake
  0%, 100%
    -moz-transform: translateX(0)
  10%, 30%, 50%, 70%, 90%
    -moz-transform: translateX(-10px)
  20%, 40%, 60%, 80%
    -moz-transform: translateX(10px)

@-o-keyframes shake
  0%, 100%
    -o-transform: translateX(0)
  10%, 30%, 50%, 70%, 90%
    -o-transform: translateX(-10px)
  20%, 40%, 60%, 80%
    -o-transform: translateX(10px)

@keyframes shake
  0%, 100%
    transform: translateX(0)
  10%, 30%, 50%, 70%, 90%
    transform: translateX(-10px)
  20%, 40%, 60%, 80%
    transform: translateX(10px)

.shake
  -webkit-animation-name: shake
  -moz-animation-name: shake
  -o-animation-name: shake
  animation-name: shake