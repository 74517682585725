@use "compass-mixins/compass/css3"
@use "components/variables"
@use "mixins/button-style"

.profile-summary
  border: 1px solid variables.$gray-4
  margin-bottom: 30px
  @include css3.border-radius(variables.$br-m)

  .top
    min-height: 160px

    .affiliation-logo
      float: right

    ul 
      color: variables.$gray-2

.profile-detailed
  min-height: 400px
  border: 1px solid variables.$gray-4
  @include css3.border-radius(0 4 4 4)

.action-panel
  background: variables.$gray-5
  border: 1px solid variables.$gray-4
  min-height: 250px
  @include css3.border-radius(variables.$br-m)