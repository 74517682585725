@use "sass:list";
@use "sass:meta";
@use "shared";
@use "../functions";
@use "../functions/lists";

// These defaults make the arguments optional for this mixin
// If you like, set different defaults in your project

$default-text-shadow-color:    #aaa !default;
$default-text-shadow-h-offset: 0px  !default;
$default-text-shadow-v-offset: 0px  !default;
$default-text-shadow-blur:     1px  !default;
$default-text-shadow-spread:   false  !default;

// Provides cross-browser text shadows when one or more shadows are needed.
// Each shadow argument should adhere to the standard css3 syntax for the
// text-shadow property.
//
// Note: if any shadow has a spread parameter, this will cause the mixin
// to emit the shadow declaration twice, first without the spread,
// then with the spread included. This allows you to progressively
// enhance the browsers that do support the spread parameter.
@mixin text-shadow(
  $shadow-1 : default,
  $shadow-2 : false,
  $shadow-3 : false,
  $shadow-4 : false,
  $shadow-5 : false,
  $shadow-6 : false,
  $shadow-7 : false,
  $shadow-8 : false,
  $shadow-9 : false,
  $shadow-10: false
) {
  @if $shadow-1 == default {
    $shadow-1: lists.compact($default-text-shadow-h-offset $default-text-shadow-v-offset $default-text-shadow-blur $default-text-shadow-spread $default-text-shadow-color);
  }
  $shadows-without-spread: list.join((),(),comma);
  $shadows: list.join((),(),comma);
  $has-spread: false;
  @each $shadow in lists.compact($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5,
                           $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10) {
    @if list.length($shadow) > 4 {
      $has-spread: true;
      $shadows-without-spread: list.append($shadows-without-spread, list.nth($shadow,1) list.nth($shadow,2) list.nth($shadow,3) list.nth($shadow,5));
      $shadows: list.append($shadows, $shadow);
    } else {
      $shadows-without-spread: list.append($shadows-without-spread, $shadow);
      $shadows: list.append($shadows, $shadow);
    }
  }
  @if $has-spread {
    text-shadow: $shadows-without-spread;
  }
  text-shadow: $shadows;
}

// Provides a single cross-browser CSS text shadow.
//
// Provides sensible defaults for the color, horizontal offset, vertical offset, blur, and spread
// according to the configuration defaults above.
@mixin single-text-shadow(
  $hoff: false,
  $voff: false,
  $blur: false,
  $spread: false,
  $color: false
) {
  // A lot of people think the color comes first. It doesn't.
  @if meta.type-of($hoff) == color {
    $temp-color: $hoff;
    $hoff: $voff;
    $voff: $blur;
    $blur: $spread;
    $spread: $color;
    $color: $temp-color;
  }
  // Can't rely on default assignment with multiple supported argument orders.
  $hoff:   if($hoff,   $hoff,   $default-text-shadow-h-offset);
  $voff:   if($voff,   $voff,   $default-text-shadow-v-offset);
  $blur:   if($blur,   $blur,   $default-text-shadow-blur    );
  $spread: if($spread, $spread, $default-text-shadow-spread  );
  $color:  if($color,  $color,  $default-text-shadow-color   );
  // We don't need experimental support for this property.
  @if $color == none or $hoff == none {
    @include text-shadow(none);
  } @else {
    @include text-shadow(lists.compact($hoff $voff $blur $spread $color));
  }
}
