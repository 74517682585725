// Core utilities
@forward "utilities/display"
@forward "utilities/float"
@forward "utilities/load"
@forward "utilities/normalize"
@forward "utilities/offset"
@forward "utilities/type"

// Core classes
@forward "classes/align"
@forward "classes/avatar"
@forward "classes/border-radius"
@forward "classes/filter"
@forward "classes/map"
@forward "classes/margin"
@forward "classes/padding"
@forward "classes/search"
@forward "classes/utility-bar"
@forward "classes/widget"

// Core components
@forward "components/alert"
@forward "components/button"
@forward "components/color"
@forward "components/form"
@forward "components/grid"
@forward "components/horizontal-rule"
@forward "components/icon-extensions"
@forward "components/label"
@forward "components/link"
@forward "components/list"
@forward "components/modal"
@forward "components/nav"
@forward "components/pagination"
@forward "components/panel"
@forward "components/popover"
@forward "components/rule"
@forward "components/tab"
@forward "components/table"
@forward "components/tooltip"
@forward "components/typography"
@forward "components/variables"

@use "components/variables"

body
  font-family: variables.$standard-font
  line-height: 1
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

*, *:before, *:after
  margin: 0
  padding: 0

*
  filter: inherit

section, div, article, aside, pre
  -webkit-box-sizing: border-box
  -moz-box-sizing: border-box
  box-sizing: border-box
  display: block
  margin: 0
  padding: 0
