@use "compass-mixins/compass/css3"
@use "components/variables"

.provider-image
  max-width: 90px
  max-height: 150px
  height: auto
  width: auto
  border: 1px solid #ccc
  @include css3.border-radius(variables.$br-m)
