// padding all
.pa-s
  padding: 10px
.pa-m
  padding: 20px
.pa-l
  padding: 30px
.pa-xl
  padding: 40px

// padding top
.pt-s
  padding-top: 10px
.pt-m
  padding-top: 20px
.pt-l
  padding-top: 30px
.pt-xl
  padding-top: 40px

// padding right
.pr-s
  padding-right: 10px
.pr-m
  padding-right: 20px
.pr-l
  padding-right: 30px
.pr-xl
  padding-right: 40px

// padding bottom
.pb-s
  padding-bottom: 10px
.pb-m
  padding-bottom: 20px
.pb-l
  padding-bottom: 30px
.pb-xl
  padding-bottom: 40px

// padding left
.pl-s
  padding-left: 10px
.pl-m
  padding-left: 20px
.pl-l
  padding-left: 30px
.pl-xl
  padding-left: 40px