@use "variables"
@use "mixins/button-style"


.button
  font-weight: variables.$fw-4
  white-space: nowrap
  -webkit-border-radius: variables.$br-m
  border-radius: variables.$br-m
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
  outline-style: none
  vertical-align: middle
  cursor: pointer
  text-decoration: none
  box-sizing: border-box
  text-align: center
  border: none

  &:hover
    text-decoration: none

  &.full-width
    width: 100%

  // sizes

  &.btn-s
    @include button-style.button-size(12px, 26px, 0 8px, 26px, 1px)

  &.btn-m
    @include button-style.button-size(14px, 30px, 0 10px, 30px, 1px)

  &.btn-l
    @include button-style.button-size(16px, 36px, 0 12px, 36px, 1px)

  &.btn-xl
    @include button-style.button-size(18px, 52px, 0 16px, 52px, 0px)

  // colors & style

  &.btn-light
    @include button-style.button-style(variables.$gray-1,variables.$gray-6)
    border: 1px solid variables.$gray-4

    &.delete
      color: #ff1a29

  &.btn-positive
    @include button-style.button-style(#fff,#25c159)

  &.btn-link
    @include button-style.button-style(#fff,#0996df)

  &.btn-soft
    @include button-style.button-style(#0996df,#fff)
    border: 1px solid #0996df

  &.btn-simple
    color: #0996df
    border-width: 0
    border-color: transparent
    background-color: transparent

    &:hover
      text-decoration: underline

  &.disabled
    opacity: .5
    border-color: #b1bac4 #9aa5b2 #718193
    text-shadow: 0 1px 0 #fff
    cursor: not-allowed
    background: #f6f7f8
    color: #2a3d51

    &:hover
      box-shadow: none
      border-color: #b1bac4 #9aa5b2 #718193

.btnGroup
  .btnGroup-item
    display: inline-block
    float: left

    &:not(:first-child)
      border-left: 0
      border-radius: 0

    &:first-child
      border-radius: 4px 0 0 4px

    &:last-child
      border-radius: 0 4px 4px 0
