@use "variables"
@use "compass-mixins/compass/css3"

.pagination
  &>li
    display: inline-block
    float: left

  &>li>a, &>li>span
    position: relative
    float: left
    padding: 6px 12px
    margin-left: -1px
    line-height: 1.42857143
    color: #337ab7
    text-decoration: none
    background-color: #fff
    border: 1px solid variables.$gray-4

  &>li:first-child>a, &>li:first-child>span
    @include css3.border-radius(variables.$br-m 0 0 variables.$br-m)

  &>li:last-child>a, &>li:last-child>span
    @include css3.border-radius(0 variables.$br-m variables.$br-m 0)

  &>li>a:focus, &>li>a:hover, &>li>span:focus, &>li>span:hover
    color: variables.$gray-1
    background-color: variables.$gray-5
    border-color: variables.$gray-4

  &>.active
    &>a, &> a:focus, & >a:hover, &>span, &>span:focus, &>span:hover
      z-index: 2
      color: #fff
      cursor: default
      background-color: variables.$ui-link
      border-color: variables.$ui-link

  &>.disabled
    &>a, & a:focus, & a:hover, &>span, &>span:focus, &>span:hover
      color: variables.$gray-2
      cursor: not-allowed
      background-color: #fff
      border-color: variables.$gray-4