@use "variables"

*
  font-size: 14px

// font size
.fs-xxs
  font-size: variables.$fs-xxs
.fs-xs
  font-size: variables.$fs-xs
.fs-s
  font-size: variables.$fs-s
.fs-m
  font-size: variables.$fs-m
.fs-l
  font-size: variables.$fs-l
.fs-xl
  font-size: variables.$fs-xl
.fs-xxl
  font-size: variables.$fs-xxl

// font weight
.fw-4
  font-weight: variables.$fw-4
.fw-5
  font-weight: variables.$fw-5
.fw-6
  font-weight: variables.$fw-6

h1, h2, h3, h4, h5
  margin: 0
  padding: 0
  font-weight: 400

  span, a
    font-size: inherit

h1
  font-size: variables.$fs-xl
h2
  font-size: variables.$fs-l
h3
  font-size: variables.$fs-m
h4
  font-size: variables.$fs-s
h5
  font-size: variables.$fs-xs

p
  font-size: variables.$fs-s
  line-height: 1.5em
  color: variables.$gray-1
  margin-bottom: variables.$sp-m

  &:first-child
    margin-top: -5px

  &:last-child
    margin-bottom: -5px

b, strong
  font-size: inherit

.ta-c
  text-align: center

.ta-l
  text-align: left

.ta-r
  text-align: right
