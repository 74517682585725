@use "sass:color"
@use "components/variables"
@use "compass-mixins/compass/css3"

.example-popover
  .popover
    position: relative
    display: block
    float: left
    width: 280px
    margin: 20px !important

.popover
  position: absolute
  top: 0
  left: 0
  z-index: variables.$zindex-popover
  display: none
  max-width: variables.$popover-max-width
  padding: 1px
  // Reset font and text properties given new insertion method
  font-family: variables.$kyruus-font
  font-size: variables.$fs-m
  font-weight: normal
  line-height: variables.$line-height-base
  text-align: left
  background-color: variables.$popover-bg
  background-clip: padding-box
  border: 1px solid variables.$popover-fallback-border-color
  border: 1px solid variables.$popover-border-color
  @include css3.border-radius(variables.$br-m)
  @include css3.box-shadow(0 5px 10px rgba(0,0,0,.2))

  // Overrides for proper insertion
  white-space: normal

  // Offset the popover to account for the popover arrow
  &.top
    margin-top: -(variables.$popover-arrow-width)
  &.right
    margin-left: variables.$popover-arrow-width
  &.bottom
    margin-top: variables.$popover-arrow-width
  &.left
    margin-left: -(variables.$popover-arrow-width)

.popover-title
  margin: 0 // reset heading margin
  padding: 8px 14px
  font-size: variables.$fs-m
  background-color: variables.$popover-title-bg
  border-bottom: 1px solid color.adjust(variables.$popover-title-bg, $lightness: -5%)
  border-radius: (variables.$br-m - 1) (variables.$br-m - 1) 0 0

.popover-content
  padding: 9px 14px

// Arrows
//
// .arrow is outer, .arrow:after is inner

.popover > .arrow
  &,
  &:after
    position: absolute
    display: block
    width: 0
    height: 0
    border-color: transparent
    border-style: solid

.popover > .arrow
  border-width: variables.$popover-arrow-outer-width

.popover > .arrow:after
  border-width: variables.$popover-arrow-width
  content: ""

.popover
  &.top > .arrow
    left: 50%
    margin-left: -(variables.$popover-arrow-outer-width)
    border-bottom-width: 0
    border-top-color: variables.$popover-arrow-outer-fallback-color // IE8 fallback
    border-top-color: variables.$popover-arrow-outer-color
    bottom: -(variables.$popover-arrow-outer-width)
    &:after
      content: " "
      bottom: 1px
      margin-left: -(variables.$popover-arrow-width)
      border-bottom-width: 0
      border-top-color: variables.$popover-arrow-color

  &.right > .arrow
    top: 50%
    left: -(variables.$popover-arrow-outer-width)
    margin-top: -(variables.$popover-arrow-outer-width)
    border-left-width: 0
    border-right-color: variables.$popover-arrow-outer-fallback-color // IE8 fallback
    border-right-color: variables.$popover-arrow-outer-color
    
    &:after
      content: " "
      left: 1px
      bottom: -(variables.$popover-arrow-width)
      border-left-width: 0
      border-right-color: variables.$popover-arrow-color

  &.bottom > .arrow
    left: 50%
    margin-left: -(variables.$popover-arrow-outer-width)
    border-top-width: 0
    border-bottom-color: variables.$popover-arrow-outer-fallback-color // IE8 fallback
    border-bottom-color: variables.$popover-arrow-outer-color
    top: -(variables.$popover-arrow-outer-width)
    
    &:after
      content: " "
      top: 1px
      margin-left: -(variables.$popover-arrow-width)
      border-top-width: 0
      border-bottom-color: variables.$popover-arrow-color

  &.left > .arrow
    top: 50%
    right: -(variables.$popover-arrow-outer-width)
    margin-top: -(variables.$popover-arrow-outer-width)
    border-right-width: 0
    border-left-color: variables.$popover-arrow-outer-fallback-color // IE8 fallback
    border-left-color: variables.$popover-arrow-outer-color
    &:after
      content: " "
      right: 1px
      border-right-width: 0
      border-left-color: variables.$popover-arrow-color
      bottom: -(variables.$popover-arrow-width)