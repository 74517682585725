@use "variables"

// font colors
.fc-gray-1
  color: variables.$gray-1
.fc-gray-2
  color: variables.$gray-2
.fc-gray-3
  color: variables.$gray-3
.fc-gray-4
  color: variables.$gray-4
.fc-gray-5
  color: variables.$gray-5

.fc-blue
  color: variables.$ui-link
.fc-success
  color: variables.$ui-success
.fc-positive
  color: variables.$ui-positive

// background colors

.bc-black
  background: variables.$black
.bc-white
  background: variables.$white

.bc-gray-1
  background: variables.$gray-1
.bc-gray-2
  background: variables.$gray-2
.bc-gray-3
  background: variables.$gray-3
.bc-gray-4
  background: variables.$gray-4
.bc-gray-5
  background: variables.$gray-5

.bc-positive
  background: variables.$ui-positive
.bc-warning
  background: variables.$ui-warning
.bc-negative
  background: variables.$ui-danger
.bc-link
  background: variables.$ui-link
.bc-soft
  background: variables.$ui-soft
