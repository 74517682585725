@mixin grid($size, $min_only: false, $range_only: false) {
  @if not $range_only {
    .col-#{$size}-1, .col-#{$size}-2, .col-#{$size}-3, .col-#{$size}-4, .col-#{$size}-5, .col-#{$size}-6, .col-#{$size}-7, .col-#{$size}-8, .col-#{$size}-9, .col-#{$size}-10, .col-#{$size}-11, .col-#{$size}-12 {
      float: left;
    }
    .col-#{$size}-12 {
      width: 100%;
    }
    .col-#{$size}-11 {
      width: 91.66666667%;
    }
    .col-#{$size}-10 {
      width: 83.33333333%;
    }
    .col-#{$size}-9 {
      width: 75%;
    }
    .col-#{$size}-8 {
      width: 66.66666667%;
    }
    .col-#{$size}-7 {
      width: 58.33333333%;
    }
    .col-#{$size}-6 {
      width: 50%;
    }
    .col-#{$size}-5 {
      width: 41.66666667%;
    }
    .col-#{$size}-4 {
      width: 33.33333333%;
    }
    .col-#{$size}-3 {
      width: 25%;
    }
    .col-#{$size}-2 {
      width: 16.66666667%;
    }
    .col-#{$size}-1 {
      width: 8.33333333%;
    }
    .col-#{$size}-pull-12 {
      right: 100%;
    }
    .col-#{$size}-pull-11 {
      right: 91.66666667%;
    }
    .col-#{$size}-pull-10 {
      right: 83.33333333%;
    }
    .col-#{$size}-pull-9 {
      right: 75%;
    }
    .col-#{$size}-pull-8 {
      right: 66.66666667%;
    }
    .col-#{$size}-pull-7 {
      right: 58.33333333%;
    }
    .col-#{$size}-pull-6 {
      right: 50%;
    }
    .col-#{$size}-pull-5 {
      right: 41.66666667%;
    }
    .col-#{$size}-pull-4 {
      right: 33.33333333%;
    }
    .col-#{$size}-pull-3 {
      right: 25%;
    }
    .col-#{$size}-pull-2 {
      right: 16.66666667%;
    }
    .col-#{$size}-pull-1 {
      right: 8.33333333%;
    }
    .col-#{$size}-pull-0 {
      right: auto;
    }
    .col-#{$size}-push-12 {
      left: 100%;
    }
    .col-#{$size}-push-11 {
      left: 91.66666667%;
    }
    .col-#{$size}-push-10 {
      left: 83.33333333%;
    }
    .col-#{$size}-push-9 {
      left: 75%;
    }
    .col-#{$size}-push-8 {
      left: 66.66666667%;
    }
    .col-#{$size}-push-7 {
      left: 58.33333333%;
    }
    .col-#{$size}-push-6 {
      left: 50%;
    }
    .col-#{$size}-push-5 {
      left: 41.66666667%;
    }
    .col-#{$size}-push-4 {
      left: 33.33333333%;
    }
    .col-#{$size}-push-3 {
      left: 25%;
    }
    .col-#{$size}-push-2 {
      left: 16.66666667%;
    }
    .col-#{$size}-push-1 {
      left: 8.33333333%;
    }
    .col-#{$size}-push-0 {
      left: auto;
    }
    .col-#{$size}-offset-12 {
      margin-left: 100%;
    }
    .col-#{$size}-offset-11 {
      margin-left: 91.66666667%;
    }
    .col-#{$size}-offset-10 {
      margin-left: 83.33333333%;
    }
    .col-#{$size}-offset-9 {
      margin-left: 75%;
    }
    .col-#{$size}-offset-8 {
      margin-left: 66.66666667%;
    }
    .col-#{$size}-offset-7 {
      margin-left: 58.33333333%;
    }
    .col-#{$size}-offset-6 {
      margin-left: 50%;
    }
    .col-#{$size}-offset-5 {
      margin-left: 41.66666667%;
    }
    .col-#{$size}-offset-4 {
      margin-left: 33.33333333%;
    }
    .col-#{$size}-offset-3 {
      margin-left: 25%;
    }
    .col-#{$size}-offset-2 {
      margin-left: 16.66666667%;
    }
    .col-#{$size}-offset-1 {
      margin-left: 8.33333333%;
    }
    .col-#{$size}-offset-0 {
      margin-left: 0%;
    }
  }
  @if not $min_only {
    .visible-#{$size} {
      display: block !important;
    }
    table.visible-#{$size} {
      display: table;
    }
    tr.visible-#{$size} {
      display: table-row !important;
    }
    th.visible-#{$size},
    td.visible-#{$size} {
      display: table-cell !important;
    }
    .visible-#{$size}-block {
      display: block !important;
    }
    .visible-#{$size}-inline {
      display: inline !important;
    }
    .visible-#{$size}-inline-block {
      display: inline-block !important;
    }
    .hidden-#{$size} {
      display: none !important;
    }
  }
}