@use "variables"

table
  max-width: 100%

  th
    border-bottom: 1px solid #9aa5b2
    padding: 0 0 8px 0
    text-align: left
    color: variables.$gray-1
    font-weight: variables.$fw-6

    &:last-child
      text-align: right

  td 
    border-top: 1px solid #dcdfe4
    color: variables.$gray-1
    
    &:last-child
      text-align: right

    strong
      color: variables.$gray-1

  &.full-width
    width: 100%

  &.table-s
    td, th
      padding: variables.$ts-s

      &:first-child
        padding: variables.$ts-s variables.$ts-s variables.$ts-s 0
      &:last-child
        padding: variables.$ts-s 0 variables.$ts-s variables.$ts-s

    td
      font-size: variables.$fs-s

  &.table-m
    td, th
      padding: variables.$ts-m

      &:first-child
        padding: variables.$ts-m variables.$ts-m variables.$ts-m 0
      &:last-child
        padding: variables.$ts-m 0 variables.$ts-m variables.$ts-m
    td
      font-size: variables.$fs-s

  &.table-l
    td, th
      padding: variables.$ts-l

      &:first-child
        padding: variables.$ts-l variables.$ts-l variables.$ts-l 0
      &:last-child
        padding: variables.$ts-l 0 variables.$ts-l variables.$ts-l
    td
      font-size: variables.$fs-m