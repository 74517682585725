@use "sass:math";
@use "sass:string";
@use "shared";
@use "../support";

// Provides cross-browser CSS opacity. Takes a number between 0 and 1 as the argument, e.g. 0.5 for 50% opacity.
//
//     @param $opacity
//         A number between 0 and 1, where 0 is transparent and 1 is opaque.

@mixin opacity($opacity) {
  @if support.$legacy-support-for-ie6 or support.$legacy-support-for-ie7 or support.$legacy-support-for-ie8 {
    filter: string.unquote("progid:DXImageTransform.Microsoft.Alpha(Opacity=#{math.round($opacity * 100)})");
  }
  opacity: $opacity;
}

// Make an element completely transparent.
@mixin transparent { @include opacity(0); }

// Make an element completely opaque.
@mixin opaque { @include opacity(1); }
