@use "components/variables";

// vertical alignment
.va-t {
  vertical-align: top;
}
.va-m {
  vertical-align: middle;
}
.va-b {
  vertical-align: bottom;
}
.va-tt {
  vertical-align: text-top;
}
.va-tb {
  vertical-align: text-bottom;
}
