@use "variables"

.nav
  display: table
  margin-bottom: 0
  font-weight: variables.$fw-6

  li
    margin: 0
    display: inline-block
    border: 0
    border-collapse: collapse
    float: left
    position: relative

    a
      padding: 1em
      color: variables.$gray-2

    &:hover
      a
        color: variables.$ui-link
        text-decoration: none

    &.active

      a
        color: variables.$ui-link

        &:hover
          text-decoration: none
          cursor: default

        &:after
          position: absolute
          bottom: -1px
          left: 1em
          right: 1em
          height: 2px
          background: variables.$ui-link
          content: ''
