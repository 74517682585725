@use "components/variables";
@use "compass-mixins/compass/css3";
  
.example-tooltip .tooltip {
  position: relative;
  display: inline-block;
  margin: 10px 20px;
  opacity: 1;
}


.tooltip {
  position: absolute;
  z-index: variables.$zindex-tooltip;
  display: block;
  visibility: visible;
  // Reset font and text properties given new insertion method
  font-family: variables.$kyruus-font;
  font-size: variables.$fs-xs;
  font-weight: normal;
  line-height: 1.4;
  @include css3.opacity(0);

  &.in     { @include css3.opacity(variables.$tooltip-opacity); }
  &.top    { margin-top:  -3px; padding: variables.$tooltip-arrow-width 0; }
  &.right  { margin-left:  3px; padding: 0 variables.$tooltip-arrow-width; }
  &.bottom { margin-top:   3px; padding: variables.$tooltip-arrow-width 0; }
  &.left   { margin-left: -3px; padding: 0 variables.$tooltip-arrow-width; }
}

// Wrapper for the tooltip content
.tooltip-inner {
  max-width: variables.$tooltip-max-width;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: variables.$tooltip-arrow-color;
  @include css3.border-radius(variables.$br-m);
}

// Arrows
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
// Note: Deprecated .top-left, .top-right, .bottom-left, and .bottom-right as of v3.3.1
.tooltip {
  &.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -(variables.$tooltip-arrow-width);
    border-width: variables.$tooltip-arrow-width variables.$tooltip-arrow-width 0;
    border-top-color: variables.$tooltip-arrow-color;
  }
  &.top-left .tooltip-arrow {
    bottom: 0;
    right: variables.$tooltip-arrow-width;
    margin-bottom: -(variables.$tooltip-arrow-width);
    border-width: variables.$tooltip-arrow-width variables.$tooltip-arrow-width 0;
    border-top-color: variables.$tooltip-arrow-color;
  }
  &.top-right .tooltip-arrow {
    bottom: 0;
    left: variables.$tooltip-arrow-width;
    margin-bottom: -(variables.$tooltip-arrow-width);
    border-width: variables.$tooltip-arrow-width variables.$tooltip-arrow-width 0;
    border-top-color: variables.$tooltip-arrow-color;
  }
  &.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -(variables.$tooltip-arrow-width);
    border-width: variables.$tooltip-arrow-width variables.$tooltip-arrow-width variables.$tooltip-arrow-width 0;
    border-right-color: variables.$tooltip-arrow-color;
  }
  &.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -(variables.$tooltip-arrow-width);
    border-width: variables.$tooltip-arrow-width 0 variables.$tooltip-arrow-width variables.$tooltip-arrow-width;
    border-left-color: variables.$tooltip-arrow-color;
  }
  &.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -(variables.$tooltip-arrow-width);
    border-width: 0 variables.$tooltip-arrow-width variables.$tooltip-arrow-width;
    border-bottom-color: variables.$tooltip-arrow-color;
  }
  &.bottom-left .tooltip-arrow {
    top: 0;
    right: variables.$tooltip-arrow-width;
    margin-top: -(variables.$tooltip-arrow-width);
    border-width: 0 variables.$tooltip-arrow-width variables.$tooltip-arrow-width;
    border-bottom-color: variables.$tooltip-arrow-color;
  }
  &.bottom-right .tooltip-arrow {
    top: 0;
    left: variables.$tooltip-arrow-width;
    margin-top: -(variables.$tooltip-arrow-width);
    border-width: 0 variables.$tooltip-arrow-width variables.$tooltip-arrow-width;
    border-bottom-color: variables.$tooltip-arrow-color;
  }
}
