@use "variables"
@use "compass-mixins/compass/css3"

input, select
  border: 1px solid #ccc
  @include css3.box-sizing(border-box)

  &:focus
    box-shadow: inset 0 1px rgba(0,0,0,0.08)
  
  &.input-s
    padding: 5px
    font-size: 12px

  &.input-m
    padding: 7px
    font-size: 16px

  &.input-l
    padding: 9px
    font-size: 18px

  &.full-width
    width: 100%

textarea
  display: block
  width: 100%
  padding: 6px 12px
  font-size: 14px
  line-height: 1.42857143
  border: 1px solid #ccc
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075)
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075)
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s
  -moz-box-sizing: border-box
  -webkit-box-sizing: border-box
  box-sizing: border-box

  &:focus
    box-shadow: inset 0 1px rgba(0,0,0,0.08)

input[type="checkbox"], input[type="radio"]
  width: auto
  box-sizing: border-box
  padding: 0
  margin-right: 5px
  border: 0px // IE 7-8 fix
  width: 14px
  height: 14px

label
  display: block    
  padding-left: 26px
  text-indent: -24px
  line-height: 22px
  padding-top: 5px
  padding-bottom: 5px
  word-wrap: break-word

  span
    white-space: pre

  &.input-s
    font-size: 12px
    line-height: 22px

  &.input-m
    font-size: 16px
    line-height: 22px

  &.input-l
    font-size: 18px
    line-height: 22px

.form-inline
  position: relative
  left: 0px
  top: 0px
  width: 100%
  height: 35px
  display: inline-block

  input
    height: 100%
    width: 100%
    float: left
    -webkit-box-sizing: border-box
    -moz-box-sizing: border-box
    box-sizing: border-box
  
  .input-wrapper
    position: absolute
    right: 20%
    left: 0px
    top : 0px
    bottom: 0px

  a.button
    position: absolute
    right: 0px
    overflow: visible
    
    &.btn-s
      top: 18px
    &.btn-m
      top: 18px
    &.btn-m
      top: 18px
    

.inputGroup
  small
    font-size: 12px
    color: #748496
    display: block