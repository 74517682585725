@use "variables"

a, a:visited
  display: inline-block
  color: variables.$ui-link
  cursor: pointer
  text-decoration: none

  &:hover
    text-decoration: underline

  // link colors
  &.dark-link
    color: variables.$gray-1

  &.light-link
    color: variables.$gray-2

  &.lighter-link
    color: variables.$gray-3