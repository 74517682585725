@use "variables"
@use "compass-mixins/compass/css3"

.label
  display: inline-block
  font-weight: 600
  padding: .35em .65em
  line-height: 1
  color: variables.$white
  text-transform: uppercase
  text-align: center
  white-space: nowrap
  vertical-align: baseline
  @include css3.border-radius(variables.$br-m)

  &.default
    background-color: variables.$gray-2
  &.primary
    background-color: variables.$ui-link
  &.success
    background-color: variables.$ui-positive
  &.info
    background-color: variables.$kyruus-blue
  &.warning
    background-color: variables.$ui-warning
  &.danger
    background-color: variables.$ui-danger