@use "sass:color"
@use "variables"
@use "compass-mixins/compass/css3"

$color-disclaimer: #f5f5f5
$color-info: #e1eff6
$color-warning: #fbf7de
$color-error: #f4dedc
$color-success: #ecf6e8

.alert
  width: 100%
  @include css3.border-radius(4px)
  &:hover
    .icons-cancel
      cursor: hand

  [class^="icons-"]
    top: 4px
    position: relative

  .icons-cancel
    top: 1px

  &.disclaimer
    background: $color-disclaimer
    border-left: 8px solid color.adjust( $color-info, $lightness: -50% )
  &.info
    background: $color-info
    border-left: 8px solid color.adjust( $color-info, $lightness: -50% )
    &:hover
      background: color.adjust( $color-info, $lightness: -3% )
  &.warning
    background: $color-warning
    border-left: 8px solid #e2cf00
    &:hover
      background: color.adjust( $color-warning, $lightness: -3% )
  &.error
    background: $color-error
    border-left: 8px solid color.adjust( $color-error, $lightness: -40% )
    &:hover
      background: color.adjust( $color-error, $lightness: -3% )
  &.success
    background: $color-success
    border-left: 8px solid color.adjust( $color-success, $lightness: -50% )
    &:hover
      background: color.adjust( $color-success, $lightness: -3% )
    &.icons-success
      color: red
