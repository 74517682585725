@use "../../mixins/grid";

@media (max-width: 767px) {
  @include grid.grid($size: 'xs');
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }

  @include grid.grid($size: 'sm', $min_only: true);
}
@media (min-width: 768px) and (max-width: 991px) {
  @include grid.grid($size: 'sm', $range_only: true);
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }

  @include grid.grid($size: 'md', $min_only: true);
}
@media (min-width: 992px) and (max-width: 1199px) {
  @include grid.grid($size: 'md', $range_only: true);
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }

  @include grid.grid($size: 'lg');
}