@use "sass:color"

$line-height-base: 1.428571429 // 20/14

// Kyruus Brand

$kyruus-blue: #1FA3E1

//-- Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

$zindex-navbar: 1000
$zindex-dropdown: 1000
$zindex-popover: 1060
$zindex-tooltip: 1070
$zindex-navbar-fixed: 1030
$zindex-modal: 1040

// colors

$black:  #000
$white: #fff

$gray-1: #2a3d51
$gray-2: #667382
$gray-3: #9aa5b2
$gray-4: #d9d9d9
$gray-5: #f5f5f5
$gray-6: #e9ebed

$ui-success:    #4FA500
$ui-positive:  #42ce67
$ui-warning:  #f0ad4e
$ui-danger:    #e54e5b
$ui-link:    #087DB9
$ui-soft:    #e7fafd

// border radius

$br-s: 2px
$br-m: 4px
$br-l: 8px

// font-sizes
$fs-xxs:  10px
$fs-xs:    12px
$fs-s:    14px
$fs-m:    16px
$fs-l:    20px
$fs-xl:    32px
$fs-xxl:  40px

//font-weight

$fw-4:  400
$fw-5:  500
$fw-6:  600

// fonts

$kyruus-font: "source-sans-pro", "Helvetica Neue", Helvetica, arial, sans-serif
$standard-font: "Helvetica Neue", Helvetica, arial, sans-serif

// spacing

$sp-xs: 5px
$sp-s: 10px
$sp-m: 20px
$sp-l: 30px
$sp-xl: 40px
$sp-xxl: 50px

// table spacing

$ts-s: 8px
$ts-m: 12px
$ts-l: 18px

//== Tooltips
//
//##

//** Tooltip max width
$tooltip-max-width:           200px !default

//** Tooltip text color
$tooltip-color:               $gray-1 !default

//** Tooltip background color
$tooltip-bg:                  #000 !default
$tooltip-opacity:             .9 !default

//** Tooltip arrow width
$tooltip-arrow-width:         5px !default

//** Tooltip arrow color
$tooltip-arrow-color:         $gray-2 !default


//== Popovers
//
//##

//** Popover body background color
$popover-bg:                          #fff !default
//** Popover maximum width
$popover-max-width:                   276px !default
//** Popover border color
$popover-border-color:                rgba(0,0,0,.2) !default
//** Popover fallback border color
$popover-fallback-border-color:       #ccc !default

//** Popover title background color
$popover-title-bg:                    color.adjust($popover-bg, $lightness: -3%) !default

//** Popover arrow width
$popover-arrow-width:                 10px !default
//** Popover arrow color
$popover-arrow-color:                 $popover-bg !default

//** Popover outer arrow width
$popover-arrow-outer-width:           ($popover-arrow-width + 1) !default
//** Popover outer arrow color
$popover-arrow-outer-color:           color.adjust($popover-border-color, $alpha: 0.05) !default
//** Popover outer arrow fallback color
$popover-arrow-outer-fallback-color:  color.adjust($popover-fallback-border-color, $lightness: -20%) !default
