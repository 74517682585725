@use "components/variables"
@use "compass-mixins/compass/css3"

.filter-group
  margin-bottom: 20px

  h3
    color: variables.$gray-2

  .filter-box
    border: 1px solid variables.$gray-4
    margin-top: 10px
    @include css3.border-radius(variables.$br-m)

    &.no-top
      margin-top: 0
      @include css3.border-radius(0 0 variables.$br-m variables.$br-m)

      ul
        @include css3.border-radius(0 0 variables.$br-m variables.$br-m)

        &li:first-child
          @include css3.border-radius(0)

    ul
      background: variables.$gray-5
      border-radius: variables.$br-m

      li

        line-height: 30px
        color: variables.$gray-1
        font-size: variables.$fs-xs
        padding-left: 10px

        &:hover
          background: #fff
          color: variables.$ui-link

        .count
          color: variables.$gray-3
          white-space: nowrap

        &:hover .count
          color: variables.$gray-1
          white-space: nowrap

        label
          cursor: pointer

        &:first-child
          @include css3.border-radius(variables.$br-m variables.$br-m 0 0)

        &.disabled, &.disabled:hover
          background: none
          color: variables.$gray-3
          cursor: not-allowed

          label, input
            cursor: not-allowed

        input[type=checkbox]:checked + label
          .count
            color: variables.$gray-1

      hr
        margin: 0px auto
        border-width: 1.5px
        border-style: solid
        border-color: variables.$gray-4

    &.multi
      li
        border-bottom: 1px solid variables.$gray-4
    &.radio
      .show-more
        border-top: 1px solid variables.$gray-4

    &.custom
      background: #eee

    .show-more
      font-size: variables.$fs-xs
      padding: 5px
      text-align: center

  .clear
    display: none
    font-size: variables.$fs-xs
    padding-top: 2px

    &.show
      display: inline-block

  &:hover
    .clear
      display: inline-block
