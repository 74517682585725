@use "compass-mixins/compass/css3"
@use "components/variables"
@use "mixins/button-style"

// Consumer Embed Widget

.consumer-embed-widget
  @include css3.border-radius(4px)

  h1
    text-align: center
    margin-bottom: 20px

  .search-bar
    margin: 0
    background: variables.$gray-5
    padding: 30px
    @include css3.border-radius(variables.$br-m)

    label
      margin-bottom: 8px

    button
      width: 100%
      font-weight: 600
      white-space: nowrap
      -webkit-border-radius: variables.$br-m
      border-radius: variables.$br-m
      -webkit-user-select: none
      -moz-user-select: none
      -ms-user-select: none
      user-select: none
      outline-style: none
      vertical-align: middle
      cursor: pointer

      &.btn-positive
        @include button-style.button-style(#fff,#25c159)
        @include button-style.button-size(16px, 34px, 0 12px, 40px, 0)

    input
      -moz-box-sizing: border-box
      -webkit-box-sizing: border-box
      box-sizing: border-box
      height: auto

    .input-m
      height: 34px
