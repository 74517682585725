@use "compass-mixins/compass/css3"
@use "components/variables"

$opacity: .15

.refresh-results
  position: relative
  opacity: 1

  &:after
    display: block
    position: absolute
    width: 200px
    height: 200px
    top: 5%
    left: 40%
    opacity: 1
    content: ""
    @include css3.border-radius(variables.$br-m)
    background-image: url("images/loading.gif")
    background-position: 50% 50%
    background-size: 50%
    background-repeat: no-repeat
    @include css3.transition-property(all)
    @include css3.transition-duration(.5s)

  .result-column
    display: inline-block
    @include css3.opacity($opacity)
    @include css3.transition-property(all)
    @include css3.transition-duration(.5s)

.update-result-item
  display: inline-block
  @include css3.opacity($opacity)
  @include css3.transition-property(all)  
  @include css3.transition-duration(.5s)