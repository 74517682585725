@use "components/bootstrap-modal/modal"
@use "components/variables"

.modal-lg-1
  width: 800px
  margin-left: -400px

.modal .modal-footer
  background-color: variables.$gray-5

.modal-content
  border: 1px solid #999
  border: 1px solid rgba(0,0,0,.1)
  border-radius: 4px
  -webkit-box-shadow: 0 3px 9px rgba(0,0,0,.5)
  box-shadow: 0 3px 12px rgba(0,0,0,.2)

.modal-backdrop.in
  opacity: 0.8

.modal-body
  padding: 25px

// Pixel shift fix
// github threads describing this issue:
//   https://github.com/twbs/bootstrap/issues/9855
//   https://github.com/twbs/bootstrap/issues/9886
body.modal-open
  overflow-y: scroll
  padding-right: 0 !important
