$icomoon-font-family: "kyruus-icon-font" !default;
$icomoon-font-path: "https://cdn.kyruus.com/lib/@kyruus/kyruus-icons/1.2.0" !default;

$icon-telehealth-doctor: "\e901";
$icon-schedule: "\e8b5";
$icon-event-available: "\e614";
$icon-mail-outline: "\e0e1";
$icon-videocam: "\e04b";
$icon-powered-by: "\41";
$icon-powered-by-logo: "\42";
$icon-pm-logo-large: "\78";
$icon-link: "\7a";
$icon-error-circle: "\e000";
$icon-error-circle-outline: "\e001";
$icon-equalizer: "\e01d";
$icon-mic: "\e029";
$icon-mic-outline: "\e02a";
$icon-play-arrow: "\e037";
$icon-book: "\e0e0";
$icon-add: "\e145";
$icon-add-circle: "\e147";
$icon-add-circle-outline: "\e148";
$icon-cancel: "\e14c";
$icon-inbox: "\e156";
$icon-remove: "\e15b";
$icon-remove-circle: "\e15c";
$icon-remove-circle-outline: "\e15d";
$icon-insert-chart: "\e24b";
$icon-edit: "\e254";
$icon-arrow-down: "\e313";
$icon-arrow-up: "\e316";
$icon-circle: "\e3a6";
$icon-hamburger-menu: "\e3c7";
$icon-arrow-left: "\e408";
$icon-arrow-right: "\e409";
$icon-circle-outline: "\e40c";
$icon-phone: "\e551";
$icon-pin-drop: "\e55e";
$icon-pin: "\e55f";
$icon-apps: "\e5c3";
$icon-arrow-left-2: "\e5c4";
$icon-arrow-down-3: "\e5c5";
$icon-arrow-up-3: "\e5c7";
$icon-arrow-right-2: "\e5c8";
$icon-cancel-circle: "\e5c9";
$icon-check: "\e5ca";
$icon-error: "\e612";
$icon-group: "\e7ef";
$icon-mood-happy: "\e7f2";
$icon-mood-bad: "\e7f3";
$icon-group-outline: "\e7fc";
$icon-person: "\e7fd";
$icon-person-outline: "\e7ff";
$icon-radio-button-active: "\e837";
$icon-account-box: "\e851";
$icon-account-circle: "\e853";
$icon-account-badge: "\e85e";
$icon-refresh: "\e863";
$icon-bookmarks: "\e866";
$icon-bookmark-outline: "\e867";
$icon-check-circle: "\e86c";
$icon-delete: "\e872";
$icon-face: "\e87c";
$icon-helper: "\e887";
$icon-cancel-circle-outline: "\e888";
$icon-home: "\e88a";
$icon-info-circle: "\e88e";
$icon-info-circle-outline: "\e88f";
$icon-print: "\e8ad";
$icon-warning: "\e8b2";
$icon-search: "\e8b6";
$icon-cog: "\e8b8";
$icon-check-badge: "\e8e8";
$icon-work: "\e8f9";
$icon-user-md: "\e900";
$icon-lightbulb-outline: "\e90f";
$icon-calendar: "\e916";
$icon-business-center: "\eb3f";
$icon-star: "\e800";
$icon-star-empty: "\e801";

