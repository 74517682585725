@use "components/variables"

@mixin button-style($font-color, $bg-color)
  color: $font-color
  background-color: $bg-color

@mixin button-size($font-size, $height, $padding, $line-height, $icon-position)
  font-size: $font-size
  height: $height
  padding: $padding
  line-height: $line-height

  [class^="icon-"], [class*=" icon-"]
    position: relative
    top: $icon-position
