@use 'style-guide/components/variables';

.Select--multi {
  .Select-placeholder {
    color: variables.$gray-2;
  }

  .Select-value {
    line-height: variables.$line-height-base;
    background-color: variables.$gray-5;
    color: variables.$gray-1;

    border: 1px solid variables.$gray-4;
    border-radius: 4px;
    cursor: default;

    float: left;

    margin-right: 5px;
    margin-top: 4px;
    font-size: inherit;
  }

  .Select-value-icon {
    position: absolute;
    border: 0px;
    &:hover,
    &:focus {
      background-color: variables.$gray-4;
      color: variables.$black;
    }
    &:active {
      background-color: variables.$gray-3;
    }
  }

  .Select-value-label {
    padding-left: 20px;
  }

  .Select-option {
    color: variables.$gray-1;

    &.is-selected {
      background-color: variables.$gray-4;
      color: variables.$gray-1;
    }

    &.is-focused {
      background-color: variables.$gray-5;
      color: variables.$gray-1;
    }
  }
}
